<template>
  <div>
    <header>
      <ConnectWallet />
    </header>

    <div class="z-0" v-if="getToken == 'false'">
      <!-- <div
        class="bg-cover h-screen w-screen bg-center flex items-center justify-center"
        style="
          background-image: url('https://cdn.discordapp.com/attachments/1091253405399388211/1115176521531936768/Phael_rainbow_pride_month_ultrarealistic_people_dressed_up_in_r_5afe21a6-fbfd-47a6-91e9-606a86fdc76c.png');
        "
      >
        <div class="text-center">
          <div class="title-wrapper">
            <h1 class="sweet-title">
              <span data-text="Sweet" class="text-5xl font-bold sm:text-8xl">
                AI INFLUENCERS
              </span>
            </h1>
          </div>
        </div>
      </div> -->

      <Free_Prompts class="mt-20" />

      <StickFooter />
    </div>
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";
import StickFooter from "@/components/Footer.vue";

import Free_Prompts from "./home/Free_Prompts.vue";

import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      products: [],
      products_2: [],
    };
  },
  components: {
    ConnectWallet,
    StickFooter,
    Free_Prompts,
  },
  computed: mapGetters(["getToken"]),

  methods: {},
  created() {},
};
</script>
<style scoped></style>
