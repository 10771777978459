<template>
  <div>
    <div class="z-0 bg-fixed w-full font-sans">
      <div
        class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-10 pt-10 p-2"
      >
        <div class="text-center">
          <hr class="h-px my-4 border-blue-500 border" />

          <h2
            class="max-w-lg font-sans text-3xl font-bold leading-none tracking-tight sm:text-4xl md:mx-auto animate-charcter"
          >
            VIRTUAL INFLUENCERS
          </h2>
          <hr class="h-px my-4 border-blue-500 border" />
        </div>

        <div class="grid grid-cols-2 gap-4 lg:grid-cols-4">
          <div>
            <div
              class="transition duration-300 ease-in-out transform-gpu hover:-translate-y-1 cursor-pointer rounded-sm rounded mt-5"
              v-for="s in models"
              :key="s"
            >
              <a :href="'/models/' + s.nickname">
                <div
                  class="inset-0 object-cover ease-in-out opacity-100 hover:opacity-0 h-full w-full rounded-xl"
                >
                  <div class="rounded">
                    <div class="">
                      <div
                        class="rounded-lg bg-cover pb-2 h-96 w-full inset-0 object-cover ease-in-out opacity-100"
                        :style="'background-image: url(' + s.img + ')'"
                      >
                        <div
                          class="flex flex-wrap content-end justify-center h-full w-full"
                        >
                          <div v-if="s.verify">
                            <div
                              class="mb-2 flex items-center p-1 text-3xl text-white font-extrabold font-sans uppercase bg-pink-400 bg-opacity-70 rounded-md"
                            >
                              <img
                                src="@/assets/gif/success.gif"
                                class="h-12"
                              />

                              {{ s.name }}
                            </div>
                          </div>
                          <div v-else>
                            <div
                              class="mb-2 flex items-center p-1 text-3xl text-white font-extrabold font-sans uppercase bg-blue-400 bg-opacity-70 rounded-md"
                            >
                              {{ s.name }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="absolute inset-0 object-cover transition duration-500 ease-in-out opacity-0 hover:opacity-100 h-full w-full rounded-xl"
                >
                  <div
                    class="rounded-lg bg-cover pb-2 h-96 w-full inset-0 object-cover ease-in-out opacity-100"
                    :style="'background-image: url(' + s.img_2 + ')'"
                  >
                    <div
                      class="flex flex-wrap content-end justify-center h-full w-full"
                    >
                      <div>
                        <div v-if="s.verify" class="flex justify-center">
                          <img src="@/assets/gif/success.gif" class="h-12" />
                        </div>

                        <h5
                          class="mb-2 p-1 text-3xl text-white font-extrabold font-sans uppercase bg-blue-400 bg-opacity-70 rounded-md"
                        >
                          {{ s.name }}
                        </h5>

                        <h3
                          class="mb-2 text-lg text-neutral-600 text-pink-600 text-center"
                        >
                          @{{ s.nickname }}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>

          <div>
            <div
              class="transition duration-300 ease-in-out transform-gpu hover:-translate-y-1 cursor-pointer rounded-sm rounded mt-5"
              v-for="s in models_2"
              :key="s"
            >
              <a :href="'/models/' + s.nickname">
                <div
                  class="inset-0 object-cover ease-in-out opacity-100 hover:opacity-0 h-full w-full rounded-xl"
                >
                  <div class="rounded">
                    <div class="">
                      <div
                        class="rounded-lg bg-cover pb-2 h-96 w-full inset-0 object-cover ease-in-out opacity-100"
                        :style="'background-image: url(' + s.img + ')'"
                      >
                        <div
                          class="flex flex-wrap content-end justify-center h-full w-full"
                        >
                          <div v-if="s.verify">
                            <div
                              class="mb-2 flex items-center p-1 text-3xl text-white font-extrabold font-sans uppercase bg-pink-400 bg-opacity-70 rounded-md"
                            >
                              <img
                                src="@/assets/gif/success.gif"
                                class="h-12"
                              />

                              {{ s.name }}
                            </div>
                          </div>
                          <div v-else>
                            <div
                              class="mb-2 flex items-center p-1 text-3xl text-white font-extrabold font-sans uppercase bg-blue-400 bg-opacity-70 rounded-md"
                            >
                              {{ s.name }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="absolute inset-0 object-cover transition duration-500 ease-in-out opacity-0 hover:opacity-100 h-full w-full rounded-xl"
                >
                  <div
                    class="rounded-lg bg-cover pb-2 h-96 w-full inset-0 object-cover ease-in-out opacity-100"
                    :style="'background-image: url(' + s.img_2 + ')'"
                  >
                    <div
                      class="flex flex-wrap content-end justify-center h-full w-full"
                    >
                      <div>
                        <div v-if="s.verify" class="flex justify-center">
                          <img src="@/assets/gif/success.gif" class="h-12" />
                        </div>

                        <h5
                          class="mb-2 p-1 text-3xl text-white font-extrabold font-sans uppercase bg-blue-400 bg-opacity-70 rounded-md"
                        >
                          {{ s.name }}
                        </h5>

                        <h3
                          class="mb-2 text-lg text-neutral-600 text-pink-600 text-center"
                        >
                          @{{ s.nickname }}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>

          <div>
            <div
              class="transition duration-300 ease-in-out transform-gpu hover:-translate-y-1 cursor-pointer rounded-sm rounded mt-5"
              v-for="s in models_3"
              :key="s"
            >
              <a :href="'/models/' + s.nickname">
                <div
                  class="inset-0 object-cover ease-in-out opacity-100 hover:opacity-0 h-full w-full rounded-xl"
                >
                  <div class="rounded">
                    <div class="">
                      <div
                        class="rounded-lg bg-cover pb-2 h-96 w-full inset-0 object-cover ease-in-out opacity-100"
                        :style="'background-image: url(' + s.img + ')'"
                      >
                        <div
                          class="flex flex-wrap content-end justify-center h-full w-full"
                        >
                          <div v-if="s.verify">
                            <div
                              class="mb-2 flex items-center p-1 text-3xl text-white font-extrabold font-sans uppercase bg-pink-400 bg-opacity-70 rounded-md"
                            >
                              <img
                                src="@/assets/gif/success.gif"
                                class="h-12"
                              />

                              {{ s.name }}
                            </div>
                          </div>
                          <div v-else>
                            <div
                              class="mb-2 flex items-center p-1 text-3xl text-white font-extrabold font-sans uppercase bg-blue-400 bg-opacity-70 rounded-md"
                            >
                              {{ s.name }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="absolute inset-0 object-cover transition duration-500 ease-in-out opacity-0 hover:opacity-100 h-full w-full rounded-xl"
                >
                  <div
                    class="rounded-lg bg-cover pb-2 h-96 w-full inset-0 object-cover ease-in-out opacity-100"
                    :style="'background-image: url(' + s.img_2 + ')'"
                  >
                    <div
                      class="flex flex-wrap content-end justify-center h-full w-full"
                    >
                      <div>
                        <div v-if="s.verify" class="flex justify-center">
                          <img src="@/assets/gif/success.gif" class="h-12" />
                        </div>

                        <h5
                          class="mb-2 p-1 text-3xl text-white font-extrabold font-sans uppercase bg-blue-400 bg-opacity-70 rounded-md"
                        >
                          {{ s.name }}
                        </h5>

                        <h3
                          class="mb-2 text-lg text-neutral-600 text-pink-600 text-center"
                        >
                          @{{ s.nickname }}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>

          <div>
            <div
              class="transition duration-300 ease-in-out transform-gpu hover:-translate-y-1 cursor-pointer rounded-sm rounded mt-5"
              v-for="s in models_4"
              :key="s"
            >
              <a :href="'/models/' + s.nickname">
                <div
                  class="inset-0 object-cover ease-in-out opacity-100 hover:opacity-0 h-full w-full rounded-xl"
                >
                  <div class="rounded">
                    <div class="">
                      <div
                        class="rounded-lg bg-cover pb-2 h-96 w-full inset-0 object-cover ease-in-out opacity-100"
                        :style="'background-image: url(' + s.img + ')'"
                      >
                        <div
                          class="flex flex-wrap content-end justify-center h-full w-full"
                        >
                          <div v-if="s.verify">
                            <div
                              class="mb-2 flex items-center p-1 text-3xl text-white font-extrabold font-sans uppercase bg-pink-400 bg-opacity-70 rounded-md"
                            >
                              <img
                                src="@/assets/gif/success.gif"
                                class="h-12"
                              />

                              {{ s.name }}
                            </div>
                          </div>
                          <div v-else>
                            <div
                              class="mb-2 flex items-center p-1 text-3xl text-white font-extrabold font-sans uppercase bg-blue-400 bg-opacity-70 rounded-md"
                            >
                              {{ s.name }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="absolute inset-0 object-cover transition duration-500 ease-in-out opacity-0 hover:opacity-100 h-full w-full rounded-xl"
                >
                  <div
                    class="rounded-lg bg-cover pb-2 h-96 w-full inset-0 object-cover ease-in-out opacity-100"
                    :style="'background-image: url(' + s.img_2 + ')'"
                  >
                    <div
                      class="flex flex-wrap content-end justify-center h-full w-full"
                    >
                      <div>
                        <div v-if="s.verify" class="flex justify-center">
                          <img src="@/assets/gif/success.gif" class="h-12" />
                        </div>

                        <h5
                          class="mb-2 p-1 text-3xl text-white font-extrabold font-sans uppercase bg-blue-400 bg-opacity-70 rounded-md"
                        >
                          {{ s.name }}
                        </h5>

                        <h3
                          class="mb-2 text-lg text-neutral-600 text-pink-600 text-center"
                        >
                          @{{ s.nickname }}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>

        <div class="flex flex-col items-center mt-8">
          <a
            href="/influencerrs"
            class="text-xl font-bold xl:text-xl text-transparent text-pink-600 transition duration-300 ease-in-out transform hover:-translate-y-1 cursor-pointer text-shadow-white"
          >
            VIRTUAL INFLUENCERS
            <i class="fas fa-arrow-circle-right"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";

export default {
  data() {
    return {
      models: [
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1112630379757719602/00103.png",
          img_2:
            "https://cdn.discordapp.com/attachments/1080058777971212370/1112631920157786143/00090-2919982846.png",
          name: "Nisa Zhang",
          nickname: "nisa_zhang",
          verify: true,
          filter: "",
        },

        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1120219446401847378/Screenshot_2023-06-18_180152.png",
          img_2:
            "https://cdn.discordapp.com/attachments/1080058777971212370/1120231273609310259/Screenshot_2023-06-18_180133.png",
          name: "MIQUELA SOUSA",
          nickname: "miquela_sousa",
          verify: false,
          filter: "filter ",
        },

        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1120218755381866606/Screenshot_2023-06-18_174606.png",
          img_2:
            "https://media.discordapp.net/attachments/1080058777971212370/1120298819960180786/Screenshot_2023-06-18_174228.png?width=990&height=1236",
          name: "Lu of Magalu",
          nickname: "magazineluiza",
          verify: false,
          filter: "filter ",
        },
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1120371064254509187/Screenshot_2023-06-18_181720.png",
          img_2:
            "https://cdn.discordapp.com/attachments/1080058777971212370/1120371187533488158/Screenshot_2023-06-18_181112.png",
          name: "GUGGIMON",
          nickname: "guggimon",
          verify: false,
          filter: "filter ",
        },
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1120560875770740816/Screenshot_2023-06-18_220052.png",
          img_2:
            "https://cdn.discordapp.com/attachments/1080058777971212370/1120560860201488514/Screenshot_2023-06-18_220109.png",
          name: "KATII",
          nickname: "katii",
          verify: false,
          filter: "filter ",
        },
      ],

      models_2: [
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1120219862367735868/Screenshot_2023-06-18_184251.png",
          img_2:
            "https://cdn.discordapp.com/attachments/1080058777971212370/1120541031415414844/Screenshot_2023-06-18_184040.png",
          name: "IMMA",
          nickname: "imma",
          verify: false,
          filter: "filter ",
        },
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1120542668653596784/Screenshot_2023-06-18_185211.png",
          img_2:
            "https://cdn.discordapp.com/attachments/1080058777971212370/1120542817668837467/Screenshot_2023-06-18_184901.png",
          name: "SHADU GRAM",
          nickname: "shadu_gram",
          verify: false,
          filter: "filter ",
        },
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1120546031344562176/Screenshot_2023-06-18_210428.png",
          img_2:
            "https://cdn.discordapp.com/attachments/1080058777971212370/1120546098352771145/Screenshot_2023-06-18_210303.png",
          name: "PLUSTICBOY",
          nickname: "plusticboy",
          verify: false,
          filter: "filter ",
        },
        {
          img: "https://uploads-ssl.webflow.com/5d7e8885cad5174a2fcb98d7/5eddd90b88219c2f54b109c0_virtual-influencer-hatsune-miku.jpg",
          img_2:
            "https://media.discordapp.net/attachments/1080058777971212370/1120367019628437516/Screenshot_2023-06-18_180634.png?width=938&height=1236",
          name: "Hatsune Miku",
          nickname: "hatsune_miku",
          verify: false,
          filter: "filter ",
        },
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1120562692097310730/Screenshot_2023-06-18_220457.png",
          img_2:
            "https://cdn.discordapp.com/attachments/1080058777971212370/1120562675915694080/Screenshot_2023-06-18_220517.png",
          name: "KYRA",
          nickname: "kyra",
          verify: false,
          filter: "filter ",
        },
      ],

      models_3: [
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1112669089882718239/00048-2890323437.png",
          img_2:
            "https://cdn.discordapp.com/attachments/1080058777971212370/1112669090725773373/00082-2325294244.png",
          name: "Ethan Lee",
          nickname: "ethan_lee",
          verify: true,
          filter: "",
        },
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1120551983124860999/Screenshot_2023-06-18_212025.png",
          img_2:
            "https://cdn.discordapp.com/attachments/1080058777971212370/1120551969698893874/Screenshot_2023-06-18_212118.png",
          name: "THEO",
          nickname: "theo",
          verify: false,
          filter: "filter ",
        },
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1120554761452793939/Screenshot_2023-06-18_214255.png",
          img_2:
            "https://cdn.discordapp.com/attachments/1080058777971212370/1120554838971924542/Screenshot_2023-06-18_213625.png",
          name: "GEICO GECKO",
          nickname: "geico_gecko",
          verify: false,
          filter: "filter ",
        },
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1120558341744230430/Screenshot_2023-06-18_215204.png",
          img_2:
            "https://cdn.discordapp.com/attachments/1080058777971212370/1120558313856307331/Screenshot_2023-06-18_215319.png",
          name: "ROZY OH",
          nickname: "rozy_oh",
          verify: false,
          filter: "filter ",
        },
      ],
      models_4: [
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1120579646711414884/Screenshot_2023-06-18_223420.png",
          img_2:
            "https://cdn.discordapp.com/attachments/1080058777971212370/1120579647533490296/Screenshot_2023-06-18_223502.png",
          name: "RINA",
          nickname: "rina",
          verify: false,
          filter: "filter ",
        },

        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1120581456029294704/Screenshot_2023-06-18_235224.png",
          img_2:
            "https://cdn.discordapp.com/attachments/1080058777971212370/1120581455169462292/Screenshot_2023-06-18_235123.png",
          name: "KAMI",
          nickname: "kami",
          verify: false,
          filter: "filter ",
        },

        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1120583072191107112/Screenshot_2023-06-19_000712.png",
          img_2:
            "https://cdn.discordapp.com/attachments/1080058777971212370/1120583096731979836/Screenshot_2023-06-19_000601.png",
          name: "ANELIE GODAR",
          nickname: "anelie_godar",
          verify: false,
          filter: "filter ",
        },

        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1120564941640966215/Screenshot_2023-06-18_221526.png",
          img_2:
            "https://cdn.discordapp.com/attachments/1080058777971212370/1120564925148975104/Screenshot_2023-06-18_221633.png",
          name: "NEFELE",
          nickname: "nefele",
          verify: false,
          filter: "filter ",
        },
      ],
    };
  },
  components: {
    ConnectWallet,
  },
  methods: {
    linkDetail(s) {
      if (s.price == "free") {
        return "/promptFree/" + s._id;
      } else {
        return "/prompt/" + s._id;
      }
    },
  },
  created() {},
};
</script>
